@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Inter', sans-serif;
}


@layer base {
  :root {
    --primary: #113559;
    --secondary: #FFFFFF;
    --light-blue: #22A6DE;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--primary);
  font-family: 'Inter', sans-serif;
}

img {
  object-fit: cover;
}

@keyframes bounce-high {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
    /* Adjust this value to change the bounce height */
  }
}

.animate-bounce-high {
  animation: bounce-high 0.6s infinite;
}

@font-face {
  font-family: 'PrimaryBold';
  src: url(Assets/Fonts/built\ titling\ bd.otf);
}

@font-face {
  font-family: 'Secondary';
  src: url(Assets/Fonts/Kollektif.ttf);
}

@font-face {
  font-family: 'SecondaryBold';
  src: url(Assets/Fonts/Kollektif-Bold.ttf);
}

@font-face {
  font-family: 'SecondaryItalic';
  src: url(Assets/Fonts/Kollektif-Italic.ttf);
}